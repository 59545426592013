import React from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { errorsVar } from "../../cache/cache";
import { useDeleteTypeMutation } from "../../types/graphql";
import { setError } from "../../misc/common";
import { TYPES } from "../../graphql/queries";

import "./styles.css";

const Button = React.lazy(() => import("../../components/common/Button"));

const DeleteType: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let errors = useReactiveVar(errorsVar);

    interface LocationState {
        typeId: string;
    }
    const locationState = location.state as LocationState;

    const [deleteType] = useDeleteTypeMutation({
        refetchQueries: (data) => {
            return [
                {
                    query: TYPES,
                },
            ];
        },
        onCompleted() {
            navigate(location.pathname + location.search);
        },
        onError(error) {
            errors = errorsVar([]);

            if (error?.message === "Some users have this type, it can't be deleted ") {
                errorsVar(
                    setError(errors, [
                        {
                            place: "delete-type.user-exist",
                            message: "* Пользователь с такой типом существует, вы не можете удалить этот тип",
                        },
                    ]),
                );
            }
        },
    });

    const existUserError = errors.find((el) => el?.place === "delete-type.user-exist");

    return (
        <div className="delete-type">
            <div className="delete-type__container">
                <p className="delete-type_title">Вы уверены, что хотите удалить тип?</p>
                {existUserError && <div className="delete-type-error">{existUserError.message}</div>}
            </div>
            <div className="">
                <Button
                    text={"Удалить"}
                    onClick={() => deleteType({ variables: { id: locationState?.typeId } })}
                    disabled={false}
                    mode="red"
                    className="login_btn"
                />
            </div>
        </div>
    );
};

export default DeleteType;
