import _ from "lodash";
import { successErrorType } from "../cache/cache";
import { DuelType } from "../types/types";

export const duelTypesNames: { [key in DuelType]: string } = {
    negotiation: "Конструктивные переговоры",
    mini: "Мини (1 раунд)",
    mini2: "Мини (2 раунда)",
    express: "Экспресс",
};

export const setError = (errors: successErrorType[], error: successErrorType[]): successErrorType[] => {
    return _.uniqWith([...errors, ...error], _.isEqual);
};

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string) => {
    const re = /^[0-9()+\- ]+$/; // eslint-disable-line
    return re.test(String(phone).toLowerCase());
};

export const validURL = (str) => {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    ); // fragment locator
    return !!pattern.test(str);
};

/**
 * Escape special characters in the given string of text.
 *
 * @param  {string} string The string to escape for inserting into HTML
 * @return {string}
 * @public
 */

export const escapeHtml = (string) => {
    var str = "" + string;
    var match = /["'&<>]/.exec(str);

    if (!match) {
        return str;
    }

    var escape;
    var html = "";
    var index = 0;
    var lastIndex = 0;

    for (index = match.index; index < str.length; index++) {
        switch (str.charCodeAt(index)) {
            case 34: // "
                escape = "&quot;";
                break;
            case 38: // &
                escape = "&amp;";
                break;
            case 39: // '
                escape = "&#39;";
                break;
            case 60: // <
                escape = "&lt;";
                break;
            case 62: // >
                escape = "&gt;";
                break;
            default:
                continue;
        }

        if (lastIndex !== index) {
            html += str.substring(lastIndex, index);
        }

        lastIndex = index + 1;
        html += escape;
    }

    return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
};

/**
 * Склонение числительных
 * countTextFormatter(count, ['найдена', 'найдено', 'найдены'])
 * @param n
 * @param titles
 * @returns
 */
export const countTextFormatter = (n: number, titles: [string, string, string]) => {
    return titles[
        n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
    ];
};

export const getAccessToken = () => {
    return process.env.REACT_APP_STAGE === "production"
        ? localStorage.getItem("AccessToken")
        : sessionStorage.getItem("AccessToken");
};

export const setAccessToken = (token: string) => {
    process.env.REACT_APP_STAGE === "production"
        ? localStorage.setItem("AccessToken", token)
        : sessionStorage.setItem("AccessToken", token);
};

export const removeAccessToken = () => {
    localStorage.removeItem("AccessToken");
    sessionStorage.removeItem("AccessToken");
};
