import { gql } from "@apollo/client";

export const REQUEST_AUTH = gql`
    query RequestAuth($email: String!) {
        requestAuth(email: $email) {
            message
        }
    }
`;

export const CHECK_NEW_EMAIL = gql`
    query CheckNewEmail($email: String!) {
        checkNewEmail(email: $email) {
            message
        }
    }
`;

export const COUNTRYFLAGCODE = gql`
    query CountryFlagCode($userIp: String!) {
        countryFlagCode(userIp: $userIp)
    }
`;

export const AUTH = gql`
    query Auth($email: String!, $tempPassword: String!) {
        auth(email: $email, tempPassword: $tempPassword) {
            accessToken
            isAdmin
        }
    }
`;

export const ROLES = gql`
    query Roles {
        roles {
            id
            name
            permissions {
                accessUserPage
                accessAllGroups
                createGroup
                registerUser
                seeUserData
                editUserData
                editPayments
                editBalance
                giveFreeDuels
                editDateTraining
                editDateMembership
                seeTransactions
                setDuelTime
                setDuelType
                setTasks
                setAdmin
                organizeDuel
                broadcastYoutube
                createTask
                participateFree
                participateFreeJudge
                participateFreeMaster
                participateFreePlayer
                seeVideo
                seeVideoAllGroups
                seeVideoCompleted
                seeVideoNotCompleted
                adminChat
                createInstruction
                setInstruction
            }
        }
    }
`;

export const ROLE = gql`
    query role($id: ID!) {
        role(id: $id) {
            id
            name
            permissions {
                accessUserPage
                accessAllGroups
                createGroup
                registerUser
                seeUserData
                editUserData
                editPayments
                editBalance
                giveFreeDuels
                editDateMembership
                editDateTraining
                seeTransactions
                setDuelTime
                setDuelType
                setTasks
                setAdmin
                organizeDuel
                broadcastYoutube
                createTask
                participateFree
                participateFreeJudge
                participateFreeMaster
                participateFreePlayer
                seeVideo
                seeVideoAllGroups
                seeVideoCompleted
                seeVideoNotCompleted
                adminChat
                createInstruction
                setInstruction
            }
        }
    }
`;

export const TYPES = gql`
    query types {
        types {
            types {
                id
                name
                duelTypes
                tasks {
                    id
                    name
                }
                connectDuel
                createDuel
                periods {
                    weekDay
                    timeFrom
                    timeTo
                }
                allTime
                paymentMethods
            }
            tasks {
                id
                name
                role1
                role2
                duelType
                description
            }
        }
    }
`;

export const TYPE = gql`
    query type($id: ID!) {
        type(id: $id) {
            id
            name
            duelTypes
            tasks {
                id
                name
            }
            connectDuel
            createDuel
            periods {
                weekDay
                timeFrom
                timeTo
            }
            allTime
            paymentMethods
        }
    }
`;
