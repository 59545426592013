import { InMemoryCache, makeVar } from "@apollo/client";
import { Type, UpdateRoleMutationVariables, UpdateTypeMutationVariables } from "../types/graphql";

export interface successErrorType {
    place?: string;
    message: string;
}

export interface data_step1 {
    email: string;
    name: string;
    lastname: string;
    phone: string;
    patronymic: string;
    communication: string[];
}

export interface data_step2 {
    aboutSchool: string;
    whyDidYouSchoose: string;
    city: string;
    profession: string;
    position: string;
    workIndustry: string;
    socialMedia: string;
    age: string;
}

export const roleEditVar = makeVar<UpdateRoleMutationVariables | null>(null);
export const typeEditVar = makeVar<Type | null>(null);
export const successVar = makeVar<successErrorType | null>(null);
export const errorsVar = makeVar<successErrorType[]>([]);
export const dataStep1Var = makeVar<data_step1>({
    email: "",
    name: "",
    lastname: "",
    phone: "",
    patronymic: "",
    communication: [],
});
export const dataStep2Var = makeVar<data_step2>({
    aboutSchool: "",
    whyDidYouSchoose: "",
    city: "",
    profession: "",
    position: "",
    workIndustry: "",
    socialMedia: "",
    age: "",
});
export const fromWhereVar = makeVar<string | null>("");

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Event: {
            keyFields: ["date", "time"],
        },
    },
});
