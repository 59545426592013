import React from "react";
import * as ReactDOM from "react-dom/client";
import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache/cache";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./phone-input.css";
import { getAccessToken } from "./misc/common";

// if (["prod"].includes(process.env.REACT_APP_STAGE || "")) {
//   Sentry.init({
//     // TODO: move dsn outside from here
//     dsn: "https://a6e6b06fd6d844f3a1947117b4f0e1e5@o4504212844904448.ingest.sentry.io/4504212849819648",
//     integrations: [new BrowserTracing()],
//     environment: process.env.REACT_APP_STAGE,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();

    return {
        headers: {
            ...headers,
            authorization: token !== null ? token : "",
        },
    };
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: cache,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <React.Suspense fallback={<div className="preloader"></div>}>
                <App />
            </React.Suspense>
        </ApolloProvider>
    </React.StrictMode>,
);

reportWebVitals();
