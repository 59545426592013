import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import "./styles.css";
import { Permissions, UpdateRoleMutationVariables } from "../../../types/graphql";
import Checkbox from "../../../components/common/Checkbox";
import { roleEditVar } from "../../../cache/cache";

interface IPermissions {
    fields: UpdateRoleMutationVariables;
    setFields: React.Dispatch<React.SetStateAction<UpdateRoleMutationVariables>>;
    changeableField: string;
    radio1Title: string;
    radio2Title: string;
    disabled?: boolean;
}

const RadioBtnsComponent: React.FC<IPermissions> = ({
    fields,
    setFields,
    changeableField,
    radio1Title,
    radio2Title,
    disabled,
}) => {
    const editFields: any = useReactiveVar(roleEditVar);

    useEffect(() => {
        if (disabled) {
            setFields({
                ...fields,
                permissions: {
                    ...fields?.permissions,
                    [changeableField]: false,
                },
            });
        }
    }, [disabled]);

    return (
        <div className="radio-btns_mainContainer">
            <div className="radio-btns_btnContainer">
                <label>
                    <input
                        type="radio"
                        name={changeableField}
                        value={3}
                        checked={fields?.permissions[changeableField] && !disabled}
                        onChange={() =>
                            setFields({
                                ...fields,
                                permissions: {
                                    ...fields?.permissions,
                                    [changeableField]: !fields?.permissions[changeableField],
                                },
                            })
                        }
                        disabled={disabled}
                    />
                    <div className="radiobox"></div>
                    <span>{radio1Title}</span>
                </label>
            </div>
            <div className="radio-btns_btnContainer">
                <label>
                    <input
                        type="radio"
                        name={changeableField}
                        value={1}
                        checked={!fields?.permissions[changeableField] && !disabled}
                        onChange={() =>
                            setFields({
                                ...fields,
                                permissions: {
                                    ...fields?.permissions,
                                    [changeableField]: !fields?.permissions[changeableField],
                                },
                            })
                        }
                        disabled={disabled}
                    />
                    <div className="radiobox"></div>
                    <span>{radio2Title}</span>
                </label>
            </div>
        </div>
    );
};

export default RadioBtnsComponent;
