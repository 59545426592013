import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import Checkbox from "../../../components/common/Checkbox";

interface IChoosePermissionItem {
    name: string;
    value: boolean;
    nameOfPermission: string;
    changePermission: (nameOfPermission: string, value: boolean) => void;
    disabled?: boolean;
}

const ChoosePermissionItem: React.FC<IChoosePermissionItem> = ({
    name,
    value,
    nameOfPermission,
    changePermission,
    disabled,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (disabled) {
            changePermission(nameOfPermission, true);
        }
    }, [disabled]);

    return (
        <div className="choose-permission_mainContainer">
            <div className="choose-permission_mainContainer">
                <Checkbox
                    id={name}
                    title={name}
                    name={name}
                    className="manage-role-checkbox"
                    checkboxValue={"1"}
                    value={value ? "1" : ""}
                    onChange={() => changePermission(nameOfPermission, value)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default ChoosePermissionItem;
