import React from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

import "./styles.css";
import { roleEditVar, typeEditVar } from "../../../cache/cache";

interface IPopupProps {
    children?: React.ReactNode;
    overflow?: boolean;
    resizeDrag?: boolean;
    backgroundColor?: string;
}

const Popup: React.FC<IPopupProps> = (props) => {
    const wrapRef = React.useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767.98px)" });

    useOnClickOutside(wrapRef, () => close());

    const close = () => {
        roleEditVar(null);
        typeEditVar(null);
        navigate(location.pathname + location.search);
    };

    /**
     * Draggable
     * https://www.thatsoftwaredude.com/content/11449/how-to-drag-elements-in-javascript-without-jquery
     **/
    let mousedown = false;
    let current;
    let currentx = 0;
    let currenty = 0;
    let offsetx = 0;
    let offsety = 0;
    let newx = 0;
    let newy = 0;
    let marginTop = 0;
    let marginLeft = 0;

    const startDrag = () => {
        mousedown = true;
        current = document.querySelector<HTMLDivElement>(".popup__wrap");

        marginTop = parseInt(window.getComputedStyle(current).getPropertyValue("margin-top"));
        marginLeft = parseInt(window.getComputedStyle(current).getPropertyValue("margin-left"));

        let rect = current.getBoundingClientRect();

        currentx = rect.left - marginLeft;
        currenty = rect.top - marginTop;

        current.onmouseup = endDrag;
        current.onmousemove = drag;
    };

    const drag = (e) => {
        if (mousedown) {
            if (offsetx === 0 && offsety === 0) {
                offsetx = e.clientX - currentx;
                offsety = e.clientY - currenty;
            }

            newx = e.clientX - offsetx;
            newy = e.clientY - offsety;

            current.style.left = newx + "px";
            current.style.top = newy + "px";
            current.style.bottom = "auto";
            current.style.right = "auto";
        }
    };

    const endDrag = (e) => {
        mousedown = false;
        currentx = 0;
        currenty = 0;
        offsetx = 0;
        offsety = 0;
        marginTop = 0;
        marginLeft = 0;
    };

    return (
        <div
            className={classNames("popup", {
                "popup--overflow": props?.overflow,
                "popup--resize-drag": props?.resizeDrag && !isTabletOrMobile,
            })}
        >
            <div className="popup__overlay"></div>
            <div
                ref={wrapRef}
                className={`popup__wrap ${props.backgroundColor === "white" && "popup__wrap--white"}`}
                style={{
                    ...(props?.resizeDrag &&
                        !isTabletOrMobile && {
                            inset: `20px auto auto ${(window.innerWidth - 600) / 2}px`,
                        }),
                }}
            >
                {props?.resizeDrag && !isTabletOrMobile && (
                    <div
                        className="popup__draggable"
                        onMouseDown={() => startDrag()}
                    ></div>
                )}
                <div
                    onClick={() => close()}
                    className="popup__close"
                />
                {props.children}
            </div>
        </div>
    );
};

export default Popup;
