import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import usePrevious from "./hooks/usePrevious";
import { successVar, errorsVar } from "./cache/cache";
import {} from "./types/graphql";
import Popup from "./components/common/Popup";
import PersonalData from "./popup/PersonalData";
import TermsOfUse from "./popup/TermsOfUse";
import ManageRole from "./popup/ManageRole";
import DeleteRole from "./popup/DeleteRole";
import ManageType from "./popup/ManageType";
import DeleteType from "./popup/DeleteType";

const Login = React.lazy(() => import("./pages/Login/Login"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const PlatformsChoice = React.lazy(() => import("./pages/PlatformsChoice"));

const App: React.FC = () => {
    useEffect(() => {}, []);

    return (
        <div className="App">
            <BrowserRouter>
                <React.Suspense fallback={<div className="preloader"></div>}>
                    <Routes>
                        <Route
                            path={"/"}
                            element={<Login />}
                        />
                        <Route
                            path={"/signup"}
                            element={<SignUp />}
                        />
                        <Route
                            path={"/platformChoice"}
                            element={<PlatformsChoice />}
                        />
                    </Routes>
                </React.Suspense>
                <Location />
            </BrowserRouter>
        </div>
    );
};

const Location: React.FC = () => {
    const location = useLocation();
    const errors = useReactiveVar(errorsVar);
    const success = useReactiveVar(successVar);
    const prevPageKey = usePrevious(location.key);

    React.useEffect(() => {
        if (location.key !== prevPageKey && prevPageKey !== undefined) {
            if (errors.length > 0) {
                errorsVar([]);
            }
            if (success !== null) {
                successVar(null);
            }
        }
    }, [prevPageKey, location, errors, success]);

    const renderPopup = (hash) => {
        switch (hash) {
            case "#personal-data":
                return <PersonalData />;
            case "#terms-of-use":
                return <TermsOfUse />;
            case "#manage-role":
                return <ManageRole />;
            case "#delete-role":
                return <DeleteRole />;
            case "#manage-type":
                return <ManageType />;
            case "#delete-type":
                return <DeleteType />;
            default:
                return "";
        }
    };
    const hashList = [
        "#personal-data",
        "#terms-of-use",
        "#manage-role",
        "#delete-role",
        "#manage-type",
        "#delete-type",
    ];
    if (hashList.includes(location.hash)) {
        return (
            <React.Suspense fallback={""}>
                <Popup
                    backgroundColor={
                        location.hash === "#manage-role" ||
                        location.hash === "#delete-role" ||
                        location.hash === "#manage-type" ||
                        location.hash === "#manage-type" ||
                        location.hash === "#delete-type"
                            ? "white"
                            : ""
                    }
                >
                    {renderPopup(location.hash)}
                </Popup>
            </React.Suspense>
        );
    }

    return <></>;
};

export default App;
