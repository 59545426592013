import React from "react";
import "./styles.css";

const PersonalData: React.FC = () => {
    return (
        <div className="task-info">
            <>
                <div className="task-info__title">{"Политика обработки персональных данных"}</div>
                <div className="task-info__roles">
                    <div className="scroll">
                        <p>Условия приватности Таллиннской школы менеджеров</p>
                        <p className="boldText">Действуют начиная с 01.03.2019 г.</p>
                        <p>
                            Tallinna Mänedzeride Kool OÜ является лицом, ответственным за обработку персональных данных.
                            В случае платной услуги Tallinna Mänedzeride Kool OÜ передает доверенному лицу EveryPay для
                            обра-ботки необходимые для проведения платежей персональные данные.
                        </p>
                        <p>
                            Принципы, на основании которых Таллиннская школа менеджеров обрабатывает Личные данные, в
                            том числе Клиентские данные, описаны в настоящих Условиях приватности (Политика
                            использова-ния файлов cookie Таллиннской школы менеджеров доступна отдельно). Условия
                            приватности при-меняются, в частности, в том случае, если Клиент использует, использовал или
                            выразил желание ис-пользовать оказываемые Таллиннской школой менеджеров услуги или
                            каким-либо другим образом связан с этими услугами. Условия приватности применяются также к
                            тем Отношениям с клиентами, которые сложились до вступления этих условий в силу.
                        </p>
                        <ol>
                            <li>
                                <span className="boldText">Понятия</span>
                                <p>
                                    Следующие термины используются в настоящих Условиях приватности в указанном
                                    значе-нии:
                                </p>
                                <ol>
                                    <li>
                                        <span className="boldText">Субъект данных</span> – это физическое лицо,
                                        информацией о котором Таллиннская школа мене-джеров располагает, или информация,
                                        с помощью которой возможно идентифицировать физическое лицо. Субъекты данных –
                                        это, например, Клиенты, являющиеся физическими ли-цами, лица, предъявляющие
                                        запросы или задающие вопросы, партнеры, представители Клиентов, являющихся
                                        юридическими лицами, лица, желающие получать новостную рассылку;
                                    </li>
                                    <li>
                                        <span className="boldText">Личные данные</span> – это всевозможная информация об
                                        идентифицированном или идентифи-цируемом Субъекте данных, в том числе о Клиенте;
                                    </li>
                                    <li>
                                        <span className="boldText">Постановление о защите данных</span> – это
                                        постановление Европейского парламента и Совета Европы (ЕС) 2016/679 от 27 апреля
                                        2016 года касательно защиты физических лиц при обра-ботке личных данных, а также
                                        свободного перемещения таких данных и признания недей-ствительной директивы
                                        95/46/ЕС;
                                    </li>
                                    <li>
                                        <span className="boldText">Клиентские данные</span> – это всевозможная
                                        информация (в том числе информация, которая считается Личными данными), которая
                                        известна Таллиннской школе менеджеров в отноше-нии Клиента или его
                                        представителей;
                                    </li>
                                    <li>
                                        <span className="boldText">Клиент</span> – это любое физическое или юридическое
                                        лицо, которое использует, использовало или выразило желание использовать
                                        оказываемые Таллиннской школой менеджеров услуги или каким-либо другим образом
                                        связано с услугами Таллиннской школы менеджеров;
                                    </li>
                                    <li>
                                        <span className="boldText">Третье лицо</span> – это любое лицо, которое не
                                        является самим Субъектом данных, Таллиннской школой менеджеров и работником
                                        Таллиннской школы менеджеров и которое в одиночку или вместе с другим лицом
                                        определяет цели и средства Обработки Личных данных;
                                    </li>
                                    <li>
                                        <span className="boldText">Договор</span> – это договор любого содержания,
                                        заключенный между Таллиннской школой менедже-ров и Клиентом;
                                    </li>
                                    <li>
                                        <span className="boldText">Условия приватности</span> – это настоящий текст,
                                        который устанавливает принципы Обработки Лич-ных данных Таллиннской школой
                                        менеджеров;
                                    </li>
                                    <li>
                                        <span className="boldText">Таллиннская школа менеджеров</span> – это Tallinna
                                        Mänedžeride Kool OÜ, регистрационный номер: 10362885, адрес: ул. Кентманни,
                                        11b-1, 10116 Таллинн. Контактные данные Таллиннской школы менеджеров приведены в
                                        пункте 12 настоящих Условий приватности;
                                    </li>
                                    <li>
                                        <span className="boldText">Обработка</span> – это любая операция, совершаемая с
                                        Личными данными Субъекта данных (в том числе сбор, запись, хранение, внесение
                                        изменений, систематизация, предоставление доступа, использование, подача
                                        запросов, передача, удаление и т. д.), независимо от способа осуществ-ления
                                        операции или используемых средств.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Общие принципы</span>
                                <ol>
                                    <li>
                                        Обработка Личных данных Таллиннской школой менеджеров осуществляется в
                                        соответствии с требованиями, изложенными в Постановлении о защите данных, законе
                                        о защите личных дан-ных и прочих соответствующих правовых актах, а также в
                                        настоящих Условиях приватности.
                                    </li>
                                    <li>
                                        Настоящие Условия приватности описывают общие принципы Обработки Личных данных
                                        Тал-линнской школой менеджеров. Дополнительные условия Обработки Клиентских
                                        данных могут быть описаны также в Договорах, прочих связанных с услугами
                                        документах и на сайте Таллинн-ской школы менеджеров.
                                    </li>
                                    <li>
                                        Настоящие Условия приватности являются неотъемлемой частью Договоров,
                                        заключаемых меж-ду Таллиннской школой менеджеров и Клиентом. Условия приватности
                                        применяются в таком объеме, в каком они не вступают в противоречие с Договором
                                        или условиями, изложенными в прочих связанных с услугами документах.
                                    </li>
                                    <li>
                                        Таллиннская школа менеджеров в рамках применяемого права обеспечивает
                                        конфиденциаль-ность Личных данных и принимает соответствующие технические и
                                        организационные меры по защите Личных данных от несанкционированного доступа,
                                        незаконной Обработки или обнаро-дования, случайной потери, внесения изменений
                                        или уничтожения.
                                    </li>
                                    <li>
                                        Для Обработки Личных данных Таллиннская школа менеджеров вправе пользоваться
                                        услугами уполномоченных обработчиков. В таких случаях Таллиннская школа
                                        менеджеров обеспечивает, чтобы Обработка Личных данных осуществлялась в
                                        соответствии с инструкциями Таллиннской школы менеджеров, применяемым правом и
                                        настоящими Условиями приватности и чтобы при-нимались соответствующие
                                        требованиям меры безопасности.
                                    </li>
                                    <li>
                                        Условия приватности дополняет опубликованное на сайте Таллиннской школы
                                        менеджеров предписание, применяемое в отношении файлов cookie. Политика
                                        использования файлов cookie Таллиннской школы менеджеров доступна на сайте
                                        Таллиннской школы менеджеров.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Способы сбора Личных данных</span>
                                <p>
                                    Таллиннская школа менеджеров собирает Личные данные, в частности, следующими
                                    способами:
                                </p>
                                <ol>
                                    <li>
                                        главным образом, Личные данные, которые Субъект данных сообщил Таллиннской школе
                                        менеджеров (например, через подачу запросов, заявлений, заключение Договоров);
                                    </li>
                                    <li>
                                        Личные данные, возникающие в результате обычного общения Субъекта данных и
                                        Таллинн-ской школы менеджеров, включая электронную переписку;
                                    </li>
                                    <li>
                                        Личные данные, четко опубликованные Субъектом данных (например, в социальных
                                        медиа);
                                    </li>
                                    <li>
                                        Личные данные, возникающие при использовании услуг Таллиннской школы менеджеров
                                        (например, осуществление перечислений и т. п.);
                                    </li>
                                    <li>
                                        Личные данные, получаемые от Третьих лиц (например, от партнеров Таллиннской
                                        школы менеджеров).
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Правовые основания Обработки Личных данных</span>
                                <p>
                                    Таллиннская школа менеджеров Обрабатывает Личные данные вкратце на следующих
                                    пра-вовых основаниях:
                                </p>
                                <ol>
                                    <li>
                                        <span className="boldText">для выполнения юридических обязанностей</span>,
                                        вытекающих из правовых актов (как внутриго-сударственная регуляция, так и
                                        правовые акты Европейского союза);
                                    </li>
                                    <li>
                                        <span className="boldText">для выполнения заключенного</span> с Клиентом{" "}
                                        <span className="boldText">Договора</span> или{" "}
                                        <span className="boldText">для подготовки к заключению Договора.</span> Для
                                        выполнения Договора Таллиннская школа менеджеров вправе использовать Личные
                                        данные без отдельного согласия Клиента;
                                    </li>
                                    <li>
                                        <span className="boldText">на основании согласия</span> Субъекта данных (в том
                                        числе Клиента) в указанных в согласии пре-делах и объеме и с указанными в
                                        согласии целями – в таком случае Таллиннская школа ме-неджеров запрашивает у
                                        лица соответствующее согласие;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            в оправданных интересах или для защиты интересов
                                        </span>{" "}
                                        самой Таллиннской школы менедже-ров.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Категории Личных данных</span>
                                <ol>
                                    <li>
                                        <span>
                                            Категории Личных данных, которые Таллиннская школа менеджеров главным
                                            образом, но не ограничиваясь ими, собирает и которые Обрабатываются:
                                        </span>
                                        <ol>
                                            <li>данные для идентификации личности (например, имя и фамилия);</li>
                                            <li>
                                                контактные данные (например, адрес, номер телефона, адрес электронной
                                                почты);
                                            </li>
                                            <li>
                                                данные о связях Субъекта данных с юридическими лицами (например, данные,
                                                предъявлен-ные Субъектом данных или полученные из открытых регистров или
                                                через Третьих лиц, для совершения операций от имени юридического лица,
                                                должность Субъекта данных в юриди-ческом лице);
                                            </li>
                                            <li>
                                                данные, необходимые для оказания услуг, или данные, необходимые для
                                                заключения дого-вора на оказание услуги (например, тема обучения или
                                                консультации, желательный период проведения обучения или консультации);
                                            </li>
                                            <li>
                                                данные, связанные с заказанными услугами (например, выполнение или
                                                невыполнение До-говоров, заключенные и прекратившие действовать
                                                Договоры, поданные заявления, запро-сы и жалобы, тема обучения или
                                                консультации);
                                            </li>
                                            <li>
                                                данные о привычках, предпочтениях и обратной связи Клиента (например,
                                                данные об ис-пользованных услугах, данные об обратной связи Клиента,
                                                запросы Субъекта данных, пре-тензии Клиента);
                                            </li>
                                            <li>
                                                данные об общении (например, данные, которые собираются через
                                                электронную почту, со-общения и другие механизмы общения (например,
                                                социальные медиа), а также Личные данные, связанные с посещением
                                                Субъектом данных сайтов Таллиннской школы менедже-ров);
                                            </li>
                                            <li>
                                                данные, полученные и/или созданные в ходе выполнения вытекающей из
                                                закона обязанно-сти (например, данные счета);
                                            </li>
                                            <li>
                                                данные о местоположении (например, желательные для проведения обучения
                                                город и стра-на).
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>
                                            Для передачи маркетинговой информации Таллиннская школа менеджеров
                                            использует, прежде всего, следующие Личные данные:
                                        </span>
                                        <ol>
                                            <li>
                                                контактные данные (например, адрес электронной почты) Субъекта данных (в
                                                том числе Кли-ента) и данные, идентифицирующие лицо (например имя и
                                                фамилия);
                                            </li>
                                            <li>
                                                информация об использованных услугах (например, тема пройденных
                                                обучений).
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>
                                            Помимо указанных в пунктах 5.1-5.2 категорий Личных данных Таллиннская школа
                                            менедже-ров вправе собирать полученные от Субъекта данных дополнительные и
                                            прочие Личные данные, если это необходимо для оказания конкретной услуги или
                                            если этого требует закон, связанный с оказанием услуги.
                                        </span>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Цели и правовые основания Обработки Личных данных</span>{" "}
                                Обработка Личных данных Таллин-нской школой менеджеров осуществляется, главным образом,
                                для того, чтобы:
                                <ol>
                                    <li>
                                        <span className="boldText">предоставлять услуги</span>, прежде всего, для
                                        заключения с Клиентом Договора и выполнения этого Договора, вынесения ценовых
                                        предложений, рассмотрения поданных Клиентом заяв-лений, установления личности
                                        Клиента, общения с Клиентом, обеспечения выполнения пла-тежного обязательства
                                        Клиента, разрешения претензий Клиента, что основывается на: вы-полнении Договора
                                        или применении соответствующих мер перед заключением Договора по желанию Клиента
                                        или выполнении юридической обязанности;
                                    </li>
                                    <li>
                                        <span className="boldText">управлять отношениями с клиентами</span>, прежде
                                        всего, для обеспечения актуальности и до-стоверности Личных данных и при
                                        необходимости исправления или дополнения этих Лич-ных данных, проверяя и
                                        дополняя Личные данные через внешние и внутренние источники, а также для ведения
                                        учета в отношении Договоров, передачи обратной связи или выхода на связь, что
                                        основывается на: выполнении Договора или применении соответствующих мер перед
                                        заключением Договора по желанию Клиента или выполнении юридической обязанности
                                        или согласии Субъекта данных или оправданной заинтересованности Таллиннской
                                        шко-лы менеджеров;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            защищать и обеспечивать интересы Клиента и/или Таллиннской школы менеджеров:
                                        </span>
                                    </li>
                                    <li>
                                        улучшать качество предложенных Таллиннской школой менеджеров услуг, а также для
                                        того, чтобы удостоверять коммерческие операции или прочее деловое общение, что
                                        основывается на: выполнении Договора или применении соответствующих мер перед
                                        заключением Договора по желанию Клиента или выполнении юридической обязанности
                                        или согласии Кли-ента или оправданной заинтересованности Таллиннской школы
                                        менеджеров в обеспечении и/или улучшении качества услуг;
                                    </li>
                                    <li>
                                        обеспечивать доверительные Отношения с клиентами и избегать случаев обмана и
                                        возник-новения ущерба, что основывается на: оправданной заинтересованности
                                        Таллиннской шко-лы менеджеров;
                                    </li>
                                    <li>
                                        развивать и/или совершенствовать продукты, услуги или ИТ-системы, а также
                                        обеспечивать защиту данных, что основывается на: оправданной заинтересованности
                                        Таллиннской школы менеджеров или юридической обязанности;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            организовывать мероприятия, собирать и публиковать обратную связь:
                                        </span>
                                    </li>
                                    <li>
                                        организовывать для Клиента мероприятия и предоставлять Клиенту возможность
                                        участво-вать в мероприятии (например, встреча выпускников), что основывается на:
                                        согласии Клиента или оправданной заинтересованности Таллиннской школы
                                        менеджеров;
                                    </li>
                                    <li>
                                        проводить анализы и собирать обратную связь, что основывается на: оправданной
                                        заинтересованности Таллиннской школы менеджеров в совершенствовании услуг
                                        Таллиннской школы менеджеров, в улучшении опыта использования Клиентом услуг и в
                                        развитии новых услуг, либо на согласии Клиента;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            избегать ненадлежащего использования услуг и обеспечивать соответствующее
                                            требова-ниям оказание услуг
                                        </span>
                                        , прежде всего, предоставлять доступ к цифровым каналам и контроли-ровать этот
                                        доступ и функционирование этих каналов, избегать несанкционированного до-ступа к
                                        цифровым каналам и их ненадлежащего использования, а также обеспечивать
                                        без-опасность информации, что основывается на: выполнении Договора или
                                        применении соот-ветствующих мер перед заключением Договора по желанию Клиента
                                        или выполнении юридической обязанности или согласии Клиента или оправданной
                                        заинтересованности Таллин-нской школы менеджеров в обеспечении контроля над
                                        полномочиями, связанными с цифровыми услугами Таллиннской школы менеджеров, над
                                        доступом к этим услугам и их функ-ционированием;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            удостоверять, осуществлять и защищать правовые требования
                                        </span>
                                        , прежде всего, сохранять сообщения и распоряжения, переданные с помощью средств
                                        связи (например, по электрон-ной почте), а также информацию и прочие процедуры,
                                        которые Таллиннская школа мене-джеров осуществила, что основывается на:
                                        выполнении Договора или применении соответствующих мер перед заключением
                                        Договора по желанию Клиента или выполнении юридической обязанности или
                                        оправданной заинтересованности Таллиннской школы менеджеров в выполнении
                                        правовых требований;
                                    </li>
                                    <li>
                                        <span className="boldText">
                                            выполнять вытекающие из закона обязанности Таллиннской школы менеджеров
                                        </span>
                                        , что основывается на: выполнении юридической обязанности.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Использование данных с маркетинговой целью</span>
                                <p>
                                    Таллиннская школа менеджеров использует Личные данные в маркетинговых целях, прежде
                                    всего, для отправки предложений по части предлагаемых Таллиннской школой менеджеров
                                    услуг, приглашений на мероприятия и прочей маркетинговой информации, то есть для
                                    осуществления прямого маркетинга, с помощью соответствующего средства связи
                                    (например, по электронной почте или телефону), если Субъект данных с этим согласен.
                                    Если Клиент не дал отдельного согласия на прямой маркетинг, Таллиннская школа
                                    менеджеров вправе Обрабатывать Личные данные Клиента с целью вынесения предложения
                                    об услугах Таллиннской школы менеджеров по части товаров или услуг, аналогичных тем,
                                    которые Клиент приобрел или использовал, в соответствии с оправданной
                                    заинтересованностью Таллиннской школы менеджеров.
                                </p>
                            </li>
                            <li>
                                <span className="boldText">Передача Личных данных</span>
                                <p>
                                    Таллиннская школа менеджеров передает Личные данные следующим принимающим сторонам:
                                </p>
                                <ol>
                                    <li>уполномоченные для этого работники Таллиннской школы менеджеров;</li>
                                    <li>
                                        Третьи лица, связанные с оказанием услуг и выполнением заключенного с Клиентом
                                        Договора – например, посредники при совершении платежей, лица, оказывающие
                                        услугу связи, ИТ-услугу и почтовую услугу, деловые партнеры, партнеры по рекламе
                                        и маркетингу и т. д.;
                                    </li>
                                    <li>
                                        аудиторы, правовые и финансовые консультанты или прочие консультанты Таллиннской
                                        школы менеджеров;
                                    </li>
                                    <li>в случае уступки права требования – новый кредитор;</li>
                                    <li>
                                        в случае если Клиент нарушил Договор – лица, оказывающие услугу взыскания
                                        задолженно-стей, суды и управляющие банкротством или управляющие по делу о
                                        неплатежеспособности;
                                    </li>
                                    <li>
                                        прочие лица, связанные с оказанием Таллиннской школе менеджеров услуг, например,
                                        лица, оказывающие почтовые услуги.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Географический регион обработки</span>
                                <ol>
                                    <li>
                                        <span>
                                            Как правило, Обработка Личных данных осуществляется в пределах Европейского
                                            союза / Европейской экономический зоны (ЕС/ЕЭЗ), и если возникнет такая
                                            необходимость, то передача осуществляется только при условии, что
                                            применяются соответствующие защитные меры. Примеры соответствующих защитных
                                            мер:
                                        </span>
                                        <ol>
                                            <li>
                                                в государстве, которое расположено за пределами ЕС/ЕЭЗ и в котором
                                                находится принима-ющая сторона, обеспечен достаточный уровень защиты
                                                данных согласно соответствующему решению Европейской комиссии;
                                            </li>
                                            <li>
                                                наличие действующего договора, который включает в себя разработанные ЕС
                                                типовые условия договора или одобренные указания по работе, сертификации
                                                и прочие аналогичные вещи, соответствующие Постановлению о защите
                                                данных;
                                            </li>
                                            <li>
                                                принимающая сторона сертифицирована на основании концепции обработки
                                                данных Privacy Shield (применяется к принимающим сторонам, находящимся в
                                                Соединенных штатах Америки).
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>
                                            В случае отсутствия соответствующих защитных мер Таллиннская школа
                                            менеджеров имеет право передать Личные данные за пределы ЕС/ЕЭЗ в тех
                                            ситуациях, когда:
                                        </span>
                                        <ol>
                                            <li>
                                                Субъект данных дал на это однозначное согласие, будучи
                                                проинформированным об отсут-ствии защитных мер;
                                            </li>
                                            <li>
                                                это необходимо для выполнения Договора, заключенного между Клиентом и
                                                Таллиннской школой менеджеров, или для применения Преддоговорных мер,
                                                которые принимаются на основании запроса Субъекта данных;
                                            </li>
                                            <li>
                                                это необходимо для того, чтобы в интересах Субъекта данных заключить
                                                договор между Таллиннской школой менеджеров и иным физическим или
                                                юридическим лицом или выпол-нить этот договор;
                                            </li>
                                            <li>
                                                это необходимо для составления, предъявления или защиты требований
                                                правового харак-тера;
                                            </li>
                                            <li>
                                                это необходимо для того, чтобы защитить существенные интересы Субъекта
                                                данных или иных лиц, когда Субъект данных физически или юридически не
                                                способен дать согласие;
                                            </li>
                                            <li>
                                                передача осуществляется из регистра, который согласно праву Европейского
                                                союза или его государства-члена предназначен для информирования
                                                общественности и открыт для озна-комления широкой общественности или
                                                всем, кто может доказать оправданную заинтере-сованность, но лишь в
                                                такой степени, в какой в конкретном случае выполнены касающиеся
                                                ознакомления условия, предусмотренные правом Европейского союза или его
                                                государства-члена;
                                            </li>
                                            <li>
                                                передача не является неоднократной, касается только ограниченного числа
                                                Субъектов дан-ных, необходима для того, чтобы защитить оправданные
                                                интересы Таллиннской школы ме-неджеров, над которыми интересы, права или
                                                свободы Субъекта не превалируют, и если Таллиннская школа менеджеров
                                                оценила все связанные с передачей данных обстоятельства и на основании
                                                этой оценки установила подходящие защитные меры для защиты личных
                                                данных. О такой передаче Таллиннская школа менеджеров извещает орган
                                                надзора.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>
                                            Для получения более подробной информации о передаче Личных данных за
                                            пределами ЕС/ЕЭЗ Субъект данных может обратиться к Таллиннской школе
                                            менеджеров, воспользовав-шись контактными данными, приведенными в пункте 12
                                            Условий приватности.
                                        </span>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Периоды хранения Личных данных</span>
                                <p>
                                    Таллиннская школа менеджеров не Обрабатывает Личные данные дольше, чем это
                                    необхо-димо для выполнения соответствующих целей, связанных с данными, в том числе
                                    для вы-полнения установленной в правовых актах обязанности хранения данных. При этом
                                    период хранения может основываться на Договорах с Клиентом (например, для разрешения
                                    спора, вытекающего из заключенного с Клиентом Договора), оправданной
                                    заинтересованности Таллиннской школы менеджеров или применяемом праве (например,
                                    законы, связанные с бухгалтерским учетом, или законы, связанные со сроком давности,
                                    прочее частное право).
                                </p>
                            </li>
                            <li>
                                <span className="boldText">Права Субъекта данных</span>
                                <p>Субъект данных имеет следующие права в связи с Обработкой его Личных данных:</p>
                                <ol>
                                    <li>
                                        получить информацию об Обработке его Личных данных, а также право запросить
                                        копию Обрабатываемых Личных данных (например, относительно целей Обработки,
                                        категорий Обрабатываемых Личных данных, сторон или категорий сторон, принимающих
                                        Личные данные, относительно периода хранения Личных данных; если Личные данные
                                        поступают не от Субъекта данных, то имеющуюся информацию об источниках их
                                        происхождения);
                                    </li>
                                    <li>
                                        запросить внесения исправлений в свои Личные данные, если они изменились или
                                        неточны по иной причине;
                                    </li>
                                    <li>
                                        предъявлять возражения в отношении Обработки своих Личных данных, когда
                                        Обработка Личных данных основывается на оправданной заинтересованности, в том
                                        числе с целью прямого маркетинга. Например, Клиент вправе запретить использовать
                                        свои контактные данные для отправки новостных рассылок – для этого Клиент может
                                        при получении марке-тингового электронного письма удалить себя из
                                        соответствующего списка получателей;
                                    </li>
                                    <li>
                                        запросить удаления своих Личных данных, например, если Таллиннская школа
                                        менеджеров не имеет права Обрабатывать такие данные, или если Обработка Личных
                                        данных осуществ-ляется на основании согласия Субъекта данных и он отозвал свое
                                        согласие. Такое право не применяется в том случае (или в таком объеме), если
                                        Обработка Личных данных, которые просят удалить, осуществляется также на других
                                        правовых основаниях, например, на осно-вании Договора или для выполнения
                                        юридических обязанностей;
                                    </li>
                                    <li>
                                        потребовать ограничить Обработку своих Личных данных, например, в то время,
                                        когда Тал-линнская школа менеджеров оценивает, имеет ли Клиент право на удаление
                                        своих Личных данных;
                                    </li>
                                    <li>
                                        получить свои Личные данные, которые он предоставил Таллиннской школе менеджеров
                                        и Обработка которых осуществляется на основании согласия или для выполнения
                                        Договора, в общепринятой машиночитаемой электронной форме, и если это технически
                                        возможно, то передать эти данные другому поставщику услуги (право на передачу
                                        данных);
                                    </li>
                                    <li>
                                        отозвать данное им согласие на Обработку своих Личных данных. В случае отзыва
                                        согласия Таллиннская школа менеджеров больше не будет Обрабатывать Личные данные
                                        Субъекта данных с той целью, с которой это делалось на основании согласия.
                                        Например, Субъект дан-ных имеет право в любой момент отозвать данное им согласие
                                        на Обработку его Личных данных с маркетинговой целью, нажав на расположенную в
                                        нижнем колонтитуле новостной рассылки ссылку «Я больше не желаю получать
                                        новостную рассылку» или сообщив об этом Таллиннской школе менеджеров по
                                        электронной почте. Согласие действует до тех пор, пока оно не будет отозвано;
                                    </li>
                                    <li>
                                        в любой момент обратиться к Таллиннской школе менеджеров, Инспекции по защите
                                        данных (сайт: www.aki.ee) или компетентному суду с жалобой, если он посчитает,
                                        что Обра-ботка его Личных данных нарушает его права и интересы.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Контактные данные</span>
                                <ol>
                                    <li>
                                        Субъект данных может применять свои права, подавать запросы или аннулировать
                                        согласия, обращаясь для этого к Таллиннской школе менеджеров. Для обсуждения
                                        тем, ка-сающихся Личных данных, и в случае возникновения вопросов с Таллиннской
                                        школой мене-джеров можно связываться по рабочим дням с 10:00 до 16:00 по
                                        телефону Таллиннской шко-лы менеджеров (372) 6466 356, – 357, (372) 5266 170 или
                                        по адресу электронной почты school@tarassov.ee.
                                    </li>
                                    <li>
                                        Таллиннская школа менеджеров отвечает на предъявленный запрос незамедлитель-но,
                                        но не позднее чем в течение 1 месяца с момента получения запроса. Если перед
                                        тем, как ответить на запрос, необходимо прояснить дополнительные обстоятельства,
                                        Таллиннская школа менеджеров может продлить срок дачи ответа, предварительно
                                        сообщив об этом Субъекту данных.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">Действие и изменения Условий приватности</span>
                                <ol>
                                    <li>
                                        Таллиннская школа менеджеров имеет право в любой момент в одностороннем по-рядке
                                        внести изменения в Условия приватности в соответствии с применяемым правом.
                                    </li>
                                    <li>
                                        Таллиннская школа менеджеров информирует Субъект данных о внесении измене-ний в
                                        Условия приватности через сайт или по электронной почте не позднее чем за один
                                        ме-сяц до вступления изменений в силу, за исключением случая, когда изменения
                                        вносятся в Условия приватности исходя из изменений, внесенных в правовые акты.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </>
        </div>
    );
};

export default PersonalData;
