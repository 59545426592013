import React from "react";
import "./styles.css";

const TermsOfUse: React.FC = () => {
    const versionsOfBrowsers = [
        {
            name: "Chrome",
            versionIos: "96.0.4664.45",
            versionWindows: "96.0.4664.45",
        },
        {
            name: "Safari",
            versionIos: "15",
            versionWindows: "",
        },
        {
            name: "Firefox",
            versionIos: "46.0.1",
            versionWindows: "95.0.2",
        },
        {
            name: "Opera",
            versionIos: "84.0.4316.90",
            versionWindows: "84.0.4316.90",
        },
    ];

    const versionsOfMobileBrowsers = [
        {
            name: "Chrome",
            versionIos: "96.0.4664.45",
            versionAndroid: "96.0.4664.45",
        },
        {
            name: "Safari",
            versionIos: "15",
            versionAndroid: "",
        },
        {
            name: "Firefox",
            versionIos: "46.0.1",
            versionAndroid: "95.1.0",
        },
        {
            name: "Opera",
            versionIos: "32.0.2",
            versionAndroid: "67.3.3447",
        },
        {
            name: "Android Browser",
            versionIos: "",
            versionAndroid: "80.0",
        },
    ];

    return (
        <div className="task-info">
            <>
                <div className="task-info__title">{"Правила и условия"}</div>
                <div className="task-info__roles">
                    <div className="scroll">
                        <h2>Условия пользования онлайн-магазина Таллиннской школы менеджеров</h2>
                        <p>Действуют с 1 ноября 2020 года</p>
                        <ol>
                            <li>
                                <span className="boldText">ОБЩИЕ ПОЛОЖЕНИЯ</span>
                                <ol>
                                    <li>
                                        Настоящие условия (далее – Условия) действуют между{" "}
                                        <span className="boldText">клиентами (далее – Покупатель)</span>{" "}
                                        интернет-магазина Таллиннской школы менеджеров (далее – интернет-магазин) и{" "}
                                        <span className="boldText">фирмой Tallinna Mänedzeride Kool OÜ</span>,
                                        регистрационный номер 10362885, адрес: Kentmanni 11B-1, Tallinn, Estonia,
                                        которая является собственником интернет-магазина, расположенного на
                                        vtarassov.com и tarassov.ru <span className="boldText">(далее – Продавец)</span>
                                        ,и применяются к правовым отношениям, возникающим при покупке интеллектуального
                                        контента vtarassov.com и tarassov.ru. В настоящих условиях устанавливаются права
                                        и обязанности, ответственность Сторон, условия приобретения контента и его
                                        оплаты, порядок поставки и рекламации.
                                    </li>
                                    <li>
                                        Покупателем может быть любое физическое лицо 18 лет или старше и любое
                                        юридическое лицо, которое перед размещением заказа подтверждает, что
                                        ознакомилось с настоящими условиями, соглашается с ними и обязуется их
                                        выполнять.
                                    </li>
                                    <li>
                                        Если Покупатель, являющийся физическим лицом, младше 18 лет, размещением заказа
                                        в интернет-магазине он подтверждает, что заключаемый им с Продавцом договор в
                                        соответствии с законом, предварительно одобрил законный представитель
                                        Покупателя, или что Покупатель выполняет взятые по договору обязательства с
                                        помощью средств, которые предоставил ему для этой цели или в свободное
                                        пользование его законный представитель или, по согласию последнего, третье лицо.
                                    </li>
                                    <li>
                                        Если Покупатель является юридическим лицом, Продавец предполагает, что лицо,
                                        размещающее заказ от имени юридического лица, обладает всеми правами и
                                        полномочиями на совершение такой сделки. В случае сомнений Продавец имеет право
                                        приостановить выполнение заказа, прервать его или отказаться от предоставления
                                        интеллектуального контента до подтверждения Покупателем права представительства
                                        Покупателя.
                                    </li>
                                    <li>
                                        Покупатель соглашается с условием, что в случае неэтичного или криминального
                                        поведения Покупателя при совершении покупок или злоупотреблении правом
                                        отступления от договора продажи Продавец имеет право отказаться от его
                                        дальнейшего обслуживания.
                                    </li>
                                    <li>
                                        Продавец оставляет за собой право изменять или дополнять настоящие условия без
                                        предварительного уведомления. Изменения публикуются на сайте vtarassov.com и
                                        tarassov.ru и вступают в силу с момента их публикации. Если заказ был передан до
                                        вступления в силу изменений условий покупки, то применяются те условия покупки,
                                        которые действовали на момент передачи заказа, за исключением случаев, когда в
                                        законе или настоящих условиях предусмотрено иное.
                                    </li>
                                    <li>
                                        Продавец не несет никакой ответственности за возможные убытки, возникшие в
                                        результате того, что Покупатель не прочел настоящие условия.
                                    </li>
                                    <li>
                                        В вопросах, неурегулированных настоящими условиями покупки, стороны исходят из
                                        действующего законодательства Эстонской Республики.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ПЕРСОНАЛЬНЫЕ ДАННЫЕ КЛИЕНТА И ИХ ЗАЩИТА</span>
                                <ol>
                                    <li>
                                        Передаваемые Покупателем Продавцу персональные данные обрабатываются в
                                        соответствии с Законом о защите данных Эстонской Республики. Все данные,
                                        переданные Покупателем Tallinna Mänedzeride Kool OÜ, используются только для
                                        выполнения заказа и при необходимости для предоставления дополнительной
                                        информации. Tallinna Mänedzeride Kool OÜ подтверждает, что все представленные
                                        клиентом интернет-магазину данные рассматриваются конфиденциально и не
                                        раскрываются третьим сторонам, за исключением случаев, предусмотренных законом и
                                        п.2.2.
                                    </li>
                                    <li>
                                        Информация, связанная с платёжной транзакцией, может быть передана для
                                        использования компанией Swedbank AS, расположенной по адресу Лиивалайа 8,
                                        Таллинн, Эстония, KRS 10060701 на условиях осуществления платежа по данной
                                        транзакции.
                                    </li>
                                    <li>
                                        Покупатель несет ответственность за то, чтобы данные его входа на сайт, в
                                        случае, если вход был выполнен (адрес электронной почты и пароль) не попали в
                                        руки третьих лиц. Продавец не несет никакой ответственности за любые
                                        последствия, которые были вызваны попаданием данных входа на сайт Покупателя в
                                        руки третьих лиц по вине Покупателя.
                                    </li>
                                    <li>
                                        Покупатель несет ответственность за точность представленных им данных. Продавец
                                        не несет никакой ответственности за любые последствия, которые были вызваны
                                        неточно введенными Покупателем данными.
                                    </li>
                                    <li>
                                        Если переданные Покупателем Продавцу данные изменяются, необходимо без
                                        промедления обновить их также на сайте, где оформляется заказ - vtarassov.com
                                        или tarassov.ru. Продавец не несет никакой ответственности за любые последствия,
                                        которые были вызваны некорректностью или устареванием введенных Продавцом
                                        данных.
                                    </li>
                                    <li>
                                        Если Покупатель пытается своими действиями или бездействием ухудшить, нарушить,
                                        помешать или повредить работе интернет-магазина vtarassov.com или tarassov.ru,
                                        Продавец имеет право без промедления и без предварительного уведомления
                                        ограничить или прервать доступ клиента к сайту и отказаться от его дальнейшего
                                        обслуживания.
                                    </li>
                                    <li>
                                        Условия конфиденциальности Tallinna Mänedzeride Kool OÜ -{" "}
                                        <a
                                            className="link"
                                            target="_blank"
                                            href="https://shop.vtarassov.com/usloviya-privatnosti"
                                            rel="noreferrer"
                                        >
                                            смотреть
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ДОСТУПНОСТЬ, ЦЕНА ИНТЕЛЛЕКТУАЛЬНОГО КОНТЕНТА</span>
                                <ol>
                                    <li>
                                        Цены на сайте{" "}
                                        <a
                                            className="link"
                                            target="_blank"
                                            href="https://shop.vtarassov.com"
                                            rel="noreferrer"
                                        >
                                            https://shop.vtarassov.com
                                        </a>{" "}
                                        отражаются с налогом с оборота.
                                    </li>
                                    <li>
                                        Доступность и цена контента на сайте{" "}
                                        <a
                                            className="link"
                                            target="_blank"
                                            href="https://shop.vtarassov.com"
                                            rel="noreferrer"
                                        >
                                            https://shop.vtarassov.com
                                        </a>{" "}
                                        могут меняться. Изменения вступают в силу с момента публикации на сайте
                                        vtarassov.com и tarassov.ru
                                    </li>
                                    <li>
                                        Tallinna Mänedzeride Kool OÜ имеет право отступить от договора и вернуть
                                        Покупателю заплаченные им деньги.
                                    </li>
                                    <li>
                                        Tallinna Mänedzeride Kool OÜ имеет право распространять информацию о новых
                                        товарах и услугах, кампаниях и скидках на сайте vtarassov.com и tarassov.ru.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">РАЗМЕЩЕНИЕ ЗАКАЗА</span>
                                <ol>
                                    <li>
                                        Покупатель может представить Продавцу заказ через систему заказов сайта
                                        vtarassov.com и tarassov.ru или по адресу электронной почты school@tarassov.ee
                                    </li>
                                    <li>
                                        Представление заказа не означает автоматического бронирования. После размещения
                                        заказа в интернет-магазине через несколько минут на Ваш адрес электронной почты
                                        придет подтверждение заказа. Если в течение часа Вы не получили подтверждения,
                                        пожалуйста, свяжитесь с нами по электронному адресу school@tarassov.ee или в
                                        рабочее время 9:00-15:00 таллиннского времени по телефону (+372) 6466356 офис,
                                        (+372) 5558 4941 мобильный.
                                    </li>
                                    <li>
                                        При заказе через другие средства связи Покупателю отправляется подтверждение
                                        заказа в течение 2 рабочих дней.
                                    </li>
                                    <li>
                                        Если выполнение заказа оказывается невозможным, представитель Продавца имеет
                                        право предложить Покупателю возможность выбора эквивалентного интеллектуального
                                        контента. Если покупатель не желает произвести замену, заказ аннулируется, а
                                        если он уже оплачен, Продавец в течение 7 рабочих дней возвращает деньги на
                                        расчетный счет Покупателя, с которого поступило перечисление.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ЗАКЛЮЧЕНИЕ ДОГОВОРА</span>
                                <ol>
                                    <li>
                                        Договор купли-продажи между Продавцом и Покупателем считается заключенным с
                                        момента, когда Покупатель представил заказ и заплатил за выбранный им
                                        интеллектуальный контент на банковский счет Продавца, если не оговорено иное.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ОПЛАТА ЗАКАЗА</span>
                                <ol>
                                    <li>Покупатель оплачивает заказ путем предоплаты на банковский счет Продавца.</li>
                                    <li>
                                        Покупатель имеет возможность выбрать один из следующих способов оплаты:
                                        перечисление на банковский счет Продавца либо платежной карточкой в
                                        онлайн-магазине Tallinna Mänedzeride Kool OÜ.
                                    </li>
                                    <li>
                                        Если Покупатель не совершил платеж за заказ в течение срока, указанного в пункте
                                        8.2 настоящих условий, Продавец имеет право аннулировать заказ, не уведомляя об
                                        этом Покупателя.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ДОСТАВКА ЗАКАЗА</span>
                                <ol>
                                    <li>
                                        Интеллектуальный контент предоставляется Покупателю в электронном виде для
                                        скачивания, если не предусмотрено иначе.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">АННУЛИРОВАНИЕ ЗАКАЗА</span>
                                <ol>
                                    <li>
                                        Покупатель имеет право аннулировать ошибочный заказ, сообщив об этом Продавцу по
                                        электронной почте school@tarassov.ee Сообщение считается переданным, в случае,
                                        если представитель Продавца отправил подтверждение получение сообщения об
                                        ошибочном заказе.{" "}
                                    </li>
                                    <li>
                                        Заказ аннулируется автоматически без уведомления об этом Покупателя в случае,
                                        если Покупатель в течение 7 рабочих дней не оплатил счет на предоплату.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ИНФОРМАЦИЯ, СОДЕРЖАЩАЯСЯ В ИНТЕРНЕТ-МАГАЗИНЕ</span>
                                <ol>
                                    <li>
                                        Продавец делает все от себя зависящее, чтобы информация, которая содержится на
                                        сайте Продавца, была корректной. Продавец имеет право в любой момент изменять,
                                        исправлять, а также полностью или частично удалять информацию на сайте
                                        vtarassov.com и tarassov.ru.
                                    </li>
                                    <li>
                                        Продавец оставляет за собой право корректировать цены согласно необходимости, не
                                        предупреждая об этом отдельно потенциальных Покупателей. Изменения цен не имеют
                                        обратной силы на уже оплаченные, но еще не доставленные заказы.
                                    </li>
                                    <li>
                                        При возникновении подозрения в несоответствии отраженной в интернет-магазине
                                        информации действительности рекомендуется перед размещением заказа связаться с
                                        Продавцом и попросить у него пояснений, см п.10.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">ОБСЛУЖИВАНИЕ ПОКУПАТЕЛЕЙ И ОБРАТНАЯ СВЯЗЬ</span>
                                <ol>
                                    <li>
                                        При возникновении вопросов, для консультации или разъяснений Покупателю
                                        рекомендуется обращаться по адресу электронной почты school@tarassov.ee или
                                        позвонить в рабочее время 9:00-15:00 таллиннского времени по телефону (+372)
                                        6466356 офис, (+372) 5558 4941 мобильный.{" "}
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">
                                    ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ И ЗАМЕНА ПРИОБРЕТЁННОГО КОНТЕНТА
                                </span>
                                <ol>
                                    <li>
                                        Денежные средства подлежат возврату в случае, если Покупатель после произведения
                                        оплаты за контент или услуги, оказываемые в связи с ним, не получил доступ к
                                        выбранной услуге или контенту по техническим причинам, возникшим по вине фирмы
                                        Tallinna Mänedzeride Kool OÜ, и данные технические неполадки не были исправлены
                                        в разумный срок.
                                    </li>
                                    <li>
                                        Расходы, связанные с возвратом или аннулированием, в том числе, аннулированием
                                        ошибочного заказа, несет Покупатель, за исключением случая, когда доставленный
                                        Покупателю контент не соответствует договору (см п.11.1.).
                                    </li>
                                    <li>
                                        В случае возврата контента, не соответствующего договору, Продавец предлагает
                                        возможность заменить его другим, аналогичным, контентом с перерасчетом цены.
                                    </li>
                                    <li>
                                        Если Покупатель не желает произвести замену, Продавец возвращает на банковский
                                        счет Покупателя сумму, уплаченную за заказ, не позднее чем в течение 14
                                        календарных дней. Возврат денег производится только на тот расчетный счет, с
                                        которого был оплачен заказ.
                                    </li>
                                    <li>
                                        Основанием для возврата приобретённого контента не может относиться невыполнение
                                        Покупателем системных требований к пользованию интеллектуальным контентом.{" "}
                                        <a
                                            className="link"
                                            target="_blank"
                                            href="https://shop.vtarassov.com/sistemnye-trebovaniya-magazin"
                                            rel="noreferrer"
                                        >
                                            Ознакомиться с системными требованиями
                                        </a>
                                    </li>
                                    <li>
                                        Tallinna Mänedzeride Kool OÜ не отвечает за невозможность использования
                                        приобретённого Покупателем контента, если она вызвана ошибками программного
                                        обеспечения, установленного на устройстве Покупателя.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="boldText">СИСТЕМНЫЕ ТРЕБОВАНИЯ</span>
                                <p>
                                    Наиболее частой причиной некорректной работы сайта, являются устаревшие версии
                                    интернет-браузеров.
                                </p>
                                <p>
                                    Мы рекомендуем использовать интернет-браузеры последних версий, например Google
                                    Chrome и Mozilla Firefox. Установите обновленную версию интернет-браузера и зайдите
                                    на сайт заново.
                                </p>
                                <p>
                                    Возможно, проблема в «cache» или «cookies» браузера. Чтобы убедиться в этом,
                                    откройте новое окно браузера в «приватном режиме» или «инкогнито» / «in private» /
                                    «incognito» или сделайте их очистку.
                                </p>
                                <p>
                                    Как включить режим «инкогнито» / «incognito» в Google Chrome читайте здесь. Как
                                    включить «приватный режим» / «in private» в Mozilla Firefox читайте{" "}
                                    <a
                                        className="link"
                                        target="_blank"
                                        href="https://support.google.com/chrome/answer/95464?hl=ru"
                                        rel="noreferrer"
                                    >
                                        здесь
                                    </a>
                                    .
                                </p>
                                <p>
                                    Если Вы пользуетесь одним из поддерживаемых нами браузеров и после обновления Вашего
                                    браузера до последней версии, сайт все равно отображается или работает некорректно,
                                    пожалуйста свяжитесь с нашей техподдержкой.
                                </p>
                                <ol>
                                    <li>
                                        Сайт Таллиннской школы менеджеров поддерживает все современные браузеры и
                                        платформы. В Windows в браузерах Internet Explorer / Microsoft Edge возможно
                                        будут небольшие отклонения.
                                    </li>
                                    <li>
                                        Браузеры ПК Windows 7 и более поздние версии. Apple macOS Sierra и более поздние
                                        версии.
                                        <div className="termsOfUse_table-container">
                                            <div className="termsOfUse_table-header">
                                                <div></div>
                                                <div>Windows</div>
                                                <div>Apple macOS</div>
                                            </div>
                                            <div className="termsOfUse_table-body">
                                                {versionsOfBrowsers.map((elem) => {
                                                    return (
                                                        <div className="termsOfUse_table-row">
                                                            <div>{elem?.name}</div>
                                                            <div>{elem?.versionWindows}</div>
                                                            <div>{elem?.versionIos}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        Мобильные устройства Android 5.0 Lollipop и более поздние версии. Apple iOS 10 и
                                        более поздние версии.
                                        <div className="termsOfUse_table-body">
                                            {versionsOfMobileBrowsers.map((elem) => {
                                                return (
                                                    <div className="termsOfUse_table-row">
                                                        <div>{elem?.name}</div>
                                                        <div>{elem?.versionAndroid}</div>
                                                        <div>{elem?.versionIos}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </li>
                                    <li>
                                        Для просмотра электронных книг рекомендуем Adobe Acrobat Reader или Foxit Reader
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </>
        </div>
    );
};

export default TermsOfUse;
