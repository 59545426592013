import React from "react";
import _ from "lodash";
import { Period } from "../../../types/graphql";
import "./styles.css";

const PeriodItem = React.lazy(() => import("../PeriodItem"));

interface IPeriodListProps {
    type: string;
    periods: Period[];
    onChange(index: number, period: Period): void;
    onDelete(index: number): void;
    onAdd(): void;
}

const PeriodList: React.FC<IPeriodListProps> = (props) => {
    const [periods, setPeriods] = React.useState(props.periods || []);
    const onChange = (index: number, value: Period) => {
        const newPeriods = _.cloneDeep(periods);
        newPeriods[index] = value;
        setPeriods(newPeriods);
        props.onChange(index, value);
    };

    const addPeriod = () => {
        const newPeriods = _.cloneDeep(periods);
        newPeriods.push({
            weekDay: -1,
            timeFrom: "00:00",
            timeTo: "00:00",
        });
        setPeriods(newPeriods);
        props.onAdd();
    };

    const onDelete = (index: number) => {
        const newPeriods = _.cloneDeep(periods);
        const filteredPeriods = [...newPeriods.slice(0, index), ...newPeriods.slice(index + 1)];
        setPeriods(filteredPeriods);
        props.onDelete(index);
    };

    return (
        <div className="periodList-wrap">
            <div className="periodList-title">
                <div className="periodList-title-text">Укажите промежутки {props.type}</div>
                <div
                    className="periodList-title-add"
                    onClick={addPeriod}
                >
                    Добавить
                </div>
            </div>
            {periods?.map((period, ind) => (
                <PeriodItem
                    index={ind}
                    period={period}
                    onChange={(val: Period) => onChange(ind, val)}
                    onDelete={() => onDelete(ind)}
                    key={`${props.type}-${ind}`}
                    error={periods[ind]?.weekDay === -1 || false}
                />
            ))}
        </div>
    );
};
export default PeriodList;
